import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useProductCategories } from "services/api/productCategories";
import Table from "common/components/table/Table";
import Loader from "common/components/loader/Loader";
import { CommonHash } from "app/types";

const columns = [
  { name: 'id', header: 'ID', type: 'number', defaultWidth: 60 },
  {
    name: 'name',
    defaultFlex: 1,
    header: 'Nombre',
  },
  {
    name: 'action',
    defaultFlex: 1,
    header: 'Acciones',
    render: ({ data }: { data: CommonHash }) => {
      return (
        <div style={{ display: 'flex', gap: '8px' }}>
          {/* <Link to={`/categorias/${data.id}`}>
            <i className="bi bi-eye" />
          </Link> */}
          <Link to={`/categorias/${data.id}/editar`}>
            <i className="bi bi-pencil-square" />
          </Link>
        </div>
      );
    },
  },

]

const ProductCategories: FunctionComponent = () => {
  const { isLoading, data: productCategories, isFetching } = useProductCategories();
  
  if (isLoading || isFetching) return <Loader />
  const filterValue = [
    { name: 'name', operator: 'contains', type: 'string', value: '' },
  ];
  
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="mb-4"> Listado Categorias </h1>
        <Link to='crear' className="add-button">Crear Categoria</Link>
      </div>
      <Table columns={columns} data={productCategories ? productCategories.data : []} filters={filterValue} />
    </div>
  )
}

export default ProductCategories;
