import React, { FunctionComponent } from "react";
import CookedReceiptForm from "./CookedReceiptForm";


const EditCookedReceipt: FunctionComponent = () => {  
  return (
    <div>
      <h1 className="mb-4"> Editar Receta </h1>
      <CookedReceiptForm />
    </div>
  )
}

export default EditCookedReceipt;
