import { NavLink } from "react-router-dom"
import { Brand } from "app/assets/images"
import styles from './Layout.module.scss'

export default function Root() {
  const linkStyle = ({ isActive }: { isActive: boolean }) =>
  `nav-item ${styles.link} ${isActive ? `${styles.activeLink}` : ''}`;

  return (
    <div className={`${styles.sidebar} d-none d-md-flex flex-column align-items-center justify-content-center pt-3` }>
      <div style={{backgroundImage: `url(${Brand})`, height: '130px', width: '130px', backgroundSize: 'contain', borderRadius: '50%'}}/>
      <div className="nav nav-pills flex-column mb-auto mt-5 w-100 px-4 gap-4">
        <NavLink className={linkStyle} to="/">
          <i className="bi bi-house-door"></i>
          Dashboard
        </NavLink>
        <NavLink className={linkStyle} to="/productos">
          <i className="bi bi-box"></i>
          Productos
        </NavLink>
        <NavLink className={linkStyle} to="/recetas">
          <i className="bi bi-box"></i>
          Recetas
        </NavLink>
        <NavLink className={linkStyle} to="/recetas-cocinadas">
          <i className="bi bi-box"></i>
          Recetas Cocinadas
        </NavLink>
        <NavLink className={linkStyle} to="/facturas">
          <i className="bi bi-box"></i>
          Facturas
        </NavLink>
        <NavLink className={linkStyle} to="/stocks">
          <i className="bi bi-box"></i>
          Stocks
        </NavLink>
        <NavLink className={linkStyle} to="/categorias">
          <i className="bi bi-box"></i>
          Categorias
        </NavLink>
        <NavLink className={linkStyle} to="/sub_categorias">
          <i className="bi bi-box"></i>
          SubCategorias
        </NavLink>
        <NavLink className={linkStyle} to="/carga-archivos">
          <i className="bi bi-file-arrow-up"></i>
          Carga archivos
        </NavLink>
      </div>

    </div>
  )
}