import request from 'utils/axios-utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiList, Invoice, CommonHash } from 'app/types';
import useAuth from 'hooks/useAuth';

export function useInvoices() {
  const { currentUser } = useAuth();
  return useQuery<ApiList<Invoice>>("invoices", async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: '/invoices', method: 'GET' });
    return response.data;
  });
}
