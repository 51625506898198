import { FormikErrors, FormikTouched, getIn } from 'formik';

interface CompProps {
  fieldName: string;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
}

export default function ErrorField({fieldName, errors, touched}: CompProps) {
  const fieldWasTouched = () => Boolean(getIn(touched, `${fieldName}`));
  
  const fieldErrorPresent = () => Boolean(getIn(errors, `${fieldName}`));
  const errorMessage = () => getIn(errors, `${fieldName}`);
  
  const errorChecker = () => {
    return fieldWasTouched() && fieldErrorPresent();
  }
  
  return (
    errorChecker() ? (
      <div className="mt-1 error-message">
        {errorMessage()}
      </div>
    ) : (
      <></>
    )
  )
}
