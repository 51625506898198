import { FormikErrors, FormikTouched } from 'formik';
import ErrorField from '../error_field/ErrorField';

interface CompProps {
  label: string;
  name: string;
  value: string | number;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  }
  errors: FormikErrors<any>;
  touched: FormikTouched<any>
}

export default function InputField({
  label,
  name,
  value,
  handleChange,
  handleBlur,
  errors,
  touched,
}: CompProps) {
  
  return (
    <div className="mb-3">
      <div className="d-flex flex-column">
        <label htmlFor={name} className="form-label">{label}</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={value || ''}
          />
        </div>
      </div>
      <ErrorField fieldName={name} errors={errors} touched={touched} />
    </div>
  )
}