import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'common/styles/index.scss';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";

import Dashboard from 'features/backoffice/dashboard';
import BackofficeLayout from 'features/backoffice/layout/Root';
import ErrorPage from 'common/components/errorPage/ErrorPage';
import FilesUpload from 'features/backoffice/files_upload';
import FileUploadForm from 'features/backoffice/files_upload/FilesUploadForm';
import Products from 'features/backoffice/products'
import NewProduct from 'features/backoffice/products/new';
import EditProduct from 'features/backoffice/products/edit';
import ShowProduct from 'features/backoffice/products/show';
import ProductStocks from 'features/backoffice/products/productStocks';
import Receipts from 'features/backoffice/receipts';
import NewReceipt from 'features/backoffice/receipts/new';
import EditReceipt from 'features/backoffice/receipts/edit';
import ShowReceipt from 'features/backoffice/receipts/show';
import CookedReceipts from 'features/backoffice/cooked_receipts';
import NewCookedReceipt from 'features/backoffice/cooked_receipts/new';
import EditCookedReceipt from 'features/backoffice/cooked_receipts/edit';
import ShowCookedReceipt from 'features/backoffice/cooked_receipts/show';
import Invoices from 'features/backoffice/invoices';
import Stocks from 'features/backoffice/stocks';
import NewStock from 'features/backoffice/stocks/new';
import EditStock from 'features/backoffice/stocks/edit';
import Login from 'features/backoffice/login/Login';
import AuthContextProvider from 'contexts/AuthContext';
import Counters from  'features/backoffice/counters';
import DailyCounters from  'features/backoffice/counters/DailyCounters';
import ProductCategories from 'features/backoffice/product_categories';
import NewProductCategory from 'features/backoffice/product_categories/new';
import EditProductCategory from 'features/backoffice/product_categories/edit';
import ProductSubCategories from 'features/backoffice/product_sub_categories';
import NewProductSubCategory from 'features/backoffice/product_sub_categories/new';
import EditProductSubCategory from 'features/backoffice/product_sub_categories/edit';
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
          <AuthContextProvider>
            <Routes>
              <Route path="/" element={<BackofficeLayout />}>
                <Route index element={<Dashboard />} />
                <Route path="productos" element={<Products />} />
                <Route path="productos/crear" element={<NewProduct />} />
                <Route path="productos/:productId" element={<ShowProduct />} />
                <Route path="productos/:productId/editar" element={<EditProduct />} />
                <Route path="productos/:productId/stocks" element={<ProductStocks />} />
                <Route path="productos/:productId/stocks/crear-stock" element={<NewStock />} />
                <Route path="productos/:productId/stocks/:stockId/editar" element={<EditStock />} />
                <Route path="recetas" element={<Receipts />} />
                <Route path="recetas/crear-receta" element={<NewReceipt />} />
                <Route path="recetas/:receiptId" element={<ShowReceipt />} />
                <Route path="recetas/:receiptId/editar" element={<EditReceipt />} />
                <Route path="recetas/:receiptId/crear-receta-cocinada" element={<NewCookedReceipt />} />
                <Route path="recetas-cocinadas" element={<CookedReceipts />} />
                <Route path="recetas-cocinadas/:cookedReceiptId" element={<ShowCookedReceipt />} />
                <Route path="recetas-cocinadas/:cookedReceiptId/editar" element={<EditCookedReceipt />} />
                <Route path="carga-archivos" element={<FilesUpload />} />
                <Route path="carga-archivos/crear" element={<FileUploadForm />} />
                <Route path="facturas" element={<Invoices />} />
                <Route path="stocks" element={<Stocks />} />
                <Route path="contadores" element={<Counters />} />
                <Route path="contadores/contadores-diarios" element={<DailyCounters />} />
                <Route path="categorias" element={<ProductCategories />} />
                <Route path="categorias/crear" element={<NewProductCategory />} />
                <Route path="categorias/:productCategoryId/editar" element={<EditProductCategory />} />
                <Route path="sub_categorias" element={<ProductSubCategories />} />
                <Route path="sub_categorias/crear" element={<NewProductSubCategory />} />
                <Route path="sub_categorias/:productSubCategoryId/editar" element={<EditProductSubCategory />} />
              </Route>
              <Route path="login" element={<Login />} />
            </Routes>
            <ToastContainer />
          </AuthContextProvider>
        <ReactQueryDevtools initialIsOpen={true} />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
