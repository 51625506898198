import React, { FunctionComponent } from "react";
import ProductCategoryForm from "./ProductCategoryForm";


const EditProductCategory: FunctionComponent = () => {  
  return (
    <div>
      <h1 className="mb-4"> Editar Categoria </h1>
      <ProductCategoryForm />
    </div>
  )
}

export default EditProductCategory;
