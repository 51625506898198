import { useContext } from 'react';
import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import useAuth from 'hooks/useAuth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
let API_URL = 'https://pedroherreraj.me';
if (process.env.NODE_ENV === 'development') {
  API_URL = 'http://localhost:3000';
}
export function useLogin() {
  const queryClient = useQueryClient();
  const { handleUserLogin } = useAuth();
  const navigate = useNavigate(); // Get navigate function from React Router
  return useMutation((user: any) => axios.post(`${API_URL}/login`,  user), {
    onSuccess: (response) => {
      const authorizationToken = response.headers.authorization.split(' ')[1];
      handleUserLogin({ ...response.data, authorizationToken });
      queryClient.invalidateQueries('user');
      toast.success("Has iniciado sesión correctamente", {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      navigate('/');
      // queryClient.setQueriesData('receipts', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }, onError: (error: AxiosError) => {
      const errorMsg = (error.response?.data || 'Ha ocurrido un error') as string;
      toast.error(errorMsg, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  })
}
