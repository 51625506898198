import React, {
  createContext, useEffect, useCallback,
} from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import { User } from 'app/types';

let logoutTimer: any;

interface AuthContextType {
  currentUser: User | null;
  handleUserLogin: (user: User) => void;
  handleUserLogout: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  handleUserLogin: () => {},
  handleUserLogout: () => {},
});

const AuthContextProvider = ({ children }: any) => {
  const [currentUser, storeUser, clearStoredUser] = useLocalStorage('user', null);
  const [sessionExpDate, storeSessionExpDate, clearSessionExpDate] = useLocalStorage('sessionExpiration');
  const navigate = useNavigate();

  const handleUserLogin = (user: User) => {
    console.log(user);
    const expiration = new Date((jwtDecode(user.authorizationToken).exp || 0) * 1000);
    storeUser(user);
    storeSessionExpDate(expiration);
  };

  const handleUserLogout = () => {
    clearStoredUser();
    clearSessionExpDate();
  };

  const handleAutomaticLogout = useCallback(() => {
    clearStoredUser();
    clearSessionExpDate();
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    if (currentUser && sessionExpDate) {
      const remainingTime = new Date(sessionExpDate).getTime() - new Date().getTime();
      logoutTimer = setTimeout(handleAutomaticLogout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [currentUser, sessionExpDate, handleAutomaticLogout]);

  return (
    <AuthContext.Provider value={{ currentUser, handleUserLogin, handleUserLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;