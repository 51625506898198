import request from 'utils/axios-utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiList, Product, ApiObject, CommonHash, Stock } from 'app/types';
import useAuth from 'hooks/useAuth';

export function useProducts() {
  const { currentUser } = useAuth();
  return useQuery<ApiList<Product>>("products", async () => {
    const response = await request({ authorizationToken: currentUser?.authorizationToken, url: '/products', method: 'GET'})
    return response.data;
  });
}

export function useNewProduct(productId: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<Product>>('product', async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: '/products/new', method: 'GET' });
    return response.data;
  }, { enabled: productId === undefined }
  )
}
export function useProduct(id: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<Product>>(['product', id], async () => {
    console.log('hasd');
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: `/products/${id}`, method: 'GET' });
    return response.data;
  },
  )
}

export function useAddProduct() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/products`, method: 'POST', data});
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('products')
      // queryClient.setQueriesData('products', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}
export function useUpdateProduct() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ productId, data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/products/${productId}`, method: 'PATCH', data});
  } , {
    onSuccess: () => {
      queryClient.invalidateQueries('products')
      // queryClient.setQueriesData('products', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}

export function useProductStocks(id: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiList<Stock>>(['prodadsuct', id], async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: `/products/${id}/stocks`, method: 'GET' });
    return response.data;
  })
}