import React, { FunctionComponent } from "react";
import StockForm from "./StockForm";


const NewStock: FunctionComponent = () => {  
  return (
    <div>
      <h1 className="mb-4"> Crear Stock</h1>
      <StockForm />
    </div>
  )
}

export default NewStock;
