import React, { FunctionComponent } from "react";
import { useProducts } from "services/api/products";
import Table from "common/components/table/Table";
import Loader from "common/components/loader/Loader";
import { CommonHash } from "app/types";
import { Link } from "react-router-dom";

const columns = [
  { name: 'id', header: 'ID', type: 'number', defaultWidth: 60 },
  {
    name: 'name',
    defaultFlex: 1,
    header: 'Producto',
  },
  {
    name: 'category',
    defaultFlex: 1,
    header: 'Categoria',
  },
  {
    name: 'sub_category',
    defaultFlex: 1,
    header: 'Subcategoría',
  },
  {
    name: 'measurement_unit',
    defaultFlex: 1,
    header: 'Unidad de medida',
  },
  {
    name: 'unit_value',
    defaultFlex: 1,
    header: 'Peso unidad',
  },
  {
    name: 'net_price',
    defaultFlex: 1,
    header: 'Precio neto',
  },
  {
    name: 'supplier',
    defaultFlex: 1,
    header: 'Proveedor',
  },
  {
    name: 'action',
    defaultFlex: 1,
    header: 'Acciones',
    render: ({ data }: { data: CommonHash }) => {
      return (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Link to={`/productos/${data.id}`}>
            <i className="bi bi-eye" />
          </Link>
          <Link to={`/productos/${data.id}/editar`}>
            <i className="bi bi-pencil-square" />
          </Link>
        </div>
      );
    },
  }
]


const Products: FunctionComponent = () => {
  const { isLoading, data: products, isFetching } = useProducts();
  
  if (isLoading || isFetching) return <Loader />
  const filterValue = [
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    { name: 'category', operator: 'contains', type: 'string', value: '' },
    { name: 'sub_category', operator: 'contains', type: 'string', value: '' },
    { name: 'supplier', operator: 'contains', type: 'string', value: '' },
  ];
  
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="mb-4"> Listado Productos </h1>
        <Link to='crear' className="add-button">Crear Producto</Link>
      </div>
      <Table columns={columns} data={products ? products.data : []} filters={filterValue} />
    </div>
  )
}

export default Products;
