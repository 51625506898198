import React, { FunctionComponent } from "react";
import { useInvoices } from "services/api/invoices";
import Table from "common/components/table/Table";
import Loader from "common/components/loader/Loader";

const columns = [
  { name: 'id', header: 'ID', type: 'number', defaultWidth: 60 },
  {
    name: 'supplier',
    defaultFlex: 1,
    header: 'Proveedor',
  },
  {
    name: 'number',
    defaultFlex: 1,
    header: 'Folio',
  },
  {
    name: 'net_amount',
    defaultFlex: 1,
    header: 'Total Neto',
  },
  {
    name: 'total_amount',
    defaultFlex: 1,
    header: 'Total',
  },
]


const Invoices: FunctionComponent = () => {
  const { isLoading, data: invoices, isFetching } = useInvoices();
  
  if (isLoading || isFetching) return <Loader />
  const filterValue = [
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    { name: 'category', operator: 'contains', type: 'string', value: '' },
    { name: 'sub_category', operator: 'contains', type: 'string', value: '' },
    { name: 'supplier', operator: 'contains', type: 'string', value: '' },
  ];
  
  return (
    <div>
      <h1> Listado de Facturas </h1>
      <Table columns={columns} data={invoices ? invoices.data : []} filters={filterValue} />
    </div>
  )
}

export default Invoices;
