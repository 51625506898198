import React, { FunctionComponent } from "react";

const Dashboard: FunctionComponent = () => {
  return (
    <div>
      <h1> Bienvenido </h1>
      <p>Aca van a ir metricas</p>
    </div>
  )
}

export default Dashboard