import request from 'utils/axios-utils';
import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiList, ApiObject, CommonHash, CookedReceipt } from 'app/types';
import useAuth from 'hooks/useAuth';
import { AxiosError } from 'axios';


export function useNewCookedReceipt(id: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<CookedReceipt>>(['cooked_receipt', id], async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: '/cooked_receipts/new', method: 'GET' });
    return response.data;
  } , { enabled: id === undefined })
}
export function useCookedReceipt(cookedReceiptId: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<CookedReceipt>>(['cooked_receipt', cookedReceiptId], async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: `/cooked_receipts/${cookedReceiptId}}`, method: 'GET' });

    return response.data;
  }, { enabled: cookedReceiptId !== undefined })
}

export function useCookedReceipts() {
  const { currentUser } = useAuth();
  return useQuery<ApiList<CookedReceipt>>("cooked_receipts", async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: '/cooked_receipts', method: 'GET' });
    return response.data;
  },{
    refetchOnWindowFocus: false, // Desactivar refetch on focus window
  });
}

export function useAddCookedReceipt() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ receiptId, data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/receipts/${receiptId}/cooked_receipt`, method: 'POST', data});
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('cooked_receipts')
      // queryClient.setQueriesData('cooked_receipts', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    },
  })
}
export function useUpdateCookedReceipt() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ cookedReceiptId, data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/cooked_receipts/${cookedReceiptId}`, method: 'PATCH', data});
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('cooked_receipts')
      // queryClient.setQueriesData('cooked_receipts', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}