import React, { FunctionComponent } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ErrorField from 'common/components/error_field/ErrorField';
import { useLogin } from 'services/api/auth';

interface FormData {
  email: string;
  password: string;
}
const FormSchema = Yup.object().shape({
  email: Yup.string().required('El email es requerido'),
  password: Yup.mixed().required('La contraseña es requerido')
})

const Login: FunctionComponent = () => {
  const { mutate: AddLogin } = useLogin();
  const formik = useFormik<FormData>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: FormSchema,
    onSubmit: async (data: FormData) => {
      AddLogin({ user: data });
    },
  });
  return (
    <div>
      <h1> Login </h1>
      <form onSubmit={formik.handleSubmit} className='d-flex flex-column align-items-center mt-5'>
      <div className="mb-3">
        <div className="d-flex flex-column">
          <label htmlFor="name" className="form-label">Email</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email || ''}
            />
          </div>
        </div>
        <ErrorField fieldName="email" errors={formik.errors} touched={formik.touched} />
      </div>
      <div className="mb-3">
        <div className="d-flex flex-column">
          <label htmlFor="password" className="form-label">Contraseña</label>
          <div className="input-group">
            <input
              className="form-control"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password || ''}
            />
          </div>
        </div>
        <ErrorField fieldName="password" errors={formik.errors} touched={formik.touched} />
      </div>
      <button
          className={`btn btn-primary`}
          type="submit"
        >
          Login
        </button>
      </form>
    </div>
  )
}

export default Login;
