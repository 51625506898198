import axios from 'axios';
import React, { FunctionComponent } from "react";
import { useFormik } from 'formik';
import { useAddFileImport } from 'services/api/fileImports';
import * as Yup from 'yup';
import Select from 'react-select';
import styles from './FilesUpload.module.scss';
import ErrorField from 'common/components/error_field/ErrorField';
import { useNavigate } from 'react-router-dom';

interface FormData {
  file: File | null;
  kind: string | null;
}
const FormSchema = Yup.object().shape({
  kind: Yup.string().required('El tipo es requerido'),
  file: Yup.mixed().required('Archivo es requerido')
})

const kindOptions = [
  { label: 'Carga de productos', value: 'products_list' },
  { label: 'Carga de listado de precios', value: 'supplier_products_list' },
  { label: 'Carga de recetas', value: 'receipts_list' },
  { label: 'Carga de facturas', value: 'invoices_list' },
]
const FileUploadForm: FunctionComponent = () => {
  const navigate = useNavigate();
  const { mutate: AddFileImport } = useAddFileImport();
  const fileRef = React.createRef<HTMLInputElement>();
  const formik = useFormik<FormData>({
    initialValues: {
      kind: null,
      file: null,
    },
    validationSchema: FormSchema,
    onSubmit: async (data: FormData) => {
      const formData = new FormData();
      formData.append('file', data.file || 'none');
      formData.append('kind', data.kind || '');
      AddFileImport({data: formData})
      navigate('/carga-archivos')
    },
  });
  const showOpenFileDialog = () => {
    fileRef?.current?.click();
  };
  console.log(formik.values);
  return (
    <div>
      <h1> Carga de archivos</h1>
      <form onSubmit={formik.handleSubmit} className='d-flex flex-column align-items-center mt-5'>
      <div className="d-flex flex-column mb-3">
        <label htmlFor='kind' className="form-label">Tipo de archivo</label>
        <Select
          isSearchable
          classNamePrefix='react-select'
          className='form-control p-0'
          placeholder='Seleccione...'
          name='kind'
          options={kindOptions}
          value={kindOptions?.find(option => option.value === formik.values.kind)}
          onChange={async (e) => {
            formik.setFieldValue("kind", e?.value)
          }}
          onBlur={() => formik.setFieldTouched('kind', true)}
          styles={{
            control: (baseStyles: any) => ({
              ...baseStyles,
              height: '100%',
              width: '100%',
              borderRadius: '8px',
              border: 'none',
            }),
          }}
        />
        <ErrorField fieldName="kind" errors={formik.errors} touched={formik.touched} />

        </div>
        <div className={styles.fileBox}>
          <input
            ref={fileRef}
            type="file"
            style={{ display: 'none' }}
            accept=".xlsx"
            onChange={(e) => formik.setFieldValue('file', e?.target?.files?.[0])}
          />
          <div>
            Haz click {' '}
            <span onClick={showOpenFileDialog} className={styles.browseLink}> aquí {' '}</span>
            para seleccionar el archivo
          </div>
          {formik.values.file && (
            <p>{formik.values.file.name}</p>
          )}
        </div>
        {formik.touched.file && formik.errors.file ? (
          <div className="text-danger">{formik.errors.file}</div>
        ) : null}
        <button
          className={`btn btn-primary ${styles.submitButton}`}
          type="submit"
          disabled={formik.values.file === undefined}
        >
          Subir Archivo
        </button>
      </form>
    </div>
  )
}

export default FileUploadForm;
