import { FunctionComponent, useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { useAddProduct, useUpdateProduct, useProduct, useNewProduct } from "services/api/products";
import Loader from "common/components/loader/Loader";
import styles from './Product.module.scss'
import InputField from "common/components/input_field/InputField";
import SelectField from "common/components/select_field/SelectField";
import { Product, ApiObject } from "app/types";
import { useProductSubCategories } from "services/api/productSubCategories";
interface FormData {
  product_sub_category_id: number;
  name: string;
  measurement_unit: string;
  unit_value: number;
}

const measurementUnitOptions = [
  {label: 'Kg', value: 'kg'},
  {label: 'Unidad', value: 'un'},
  {label: 'Lt', value: 'lt'},
]

const ProductForm: FunctionComponent = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  let product: ApiObject<Product> | undefined;
  let isProductLoading = false;
  ({ data: product, isLoading: isProductLoading } = useNewProduct(productId));
  ({ data: product, isLoading: isProductLoading } = useProduct(productId));
  const { data: productSubCategories, isLoading: isProductSubCategoriesLoading } = useProductSubCategories();
  const { mutate: AddProduct, isLoading: isPostLoading } = useAddProduct(); 
  const { mutate: UpdateProduct, isLoading: isPatchLoading } = useUpdateProduct(); 
  const FormSchema = Yup.object().shape({
    product_sub_category_id: Yup.number().required('La categoria es requerido'),
    name: Yup.string().required('El nombre es requerido'),
    unit_value: Yup.number().required('El peso por unidad es requerida').min(0.001, 'El peso por unidad tiene que ser mayor a 0'),
    measurement_unit: Yup.string().required('La unidad de medida es requerida'),
  });
  const initialValues: FormData = {
    product_sub_category_id: product?.data.product_sub_category_id || 0,
    name: product?.data.name || '',
    measurement_unit: product?.data.measurement_unit || '',
    unit_value: product?.data.unit_value || 0,
  }

  const productSubCategoriesOptions = productSubCategories?.data.map((productSubCategory) => (
    {
      label: productSubCategory.name,
      value: productSubCategory.id,
    } 
  )) || [];

  if (isProductLoading || isProductSubCategoriesLoading) return <Loader />

  
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={async (values) => {
          if (productId === undefined) {
            AddProduct({productId: productId, data: values});
            navigate("/productos");
          } else {
            UpdateProduct({ productId, data: values})
            navigate("/productos");
          }
        }}
      >
        {({ values, handleChange, handleBlur, setFieldTouched, setFieldValue, errors, touched }) => {
          return (
            <Form className={styles.form}>
              <SelectField label="SubCategoria" name="product_sub_category_id" value={values.product_sub_category_id} options={productSubCategoriesOptions} matchType="value" errors={errors} touched={touched} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
              <InputField label="Nombre" name="name" value={values.name} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
              <SelectField label="Unidad de medida" name="measurement_unit" value={values.measurement_unit} options={measurementUnitOptions} matchType="value" errors={errors} touched={touched} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
              <InputField label="Peso unidad" name="unit_value" value={values.unit_value} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
              <div className="d-flex align-items-center justify-content-center">
                <button type="submit" className="mt-4 submit-button" disabled={isPostLoading || isPatchLoading}>{productId !== undefined ? 'Editar' : 'Crear'}</button>
              </div>
            </Form>
          )
        }
      }
      </Formik>
    </div>
  )
}

export default ProductForm;


