import React, { FunctionComponent } from "react";
import { useCookedReceipts } from "services/api/cookedReceipts";
import Table from "common/components/table/Table";
import Loader from "common/components/loader/Loader";
import { Link } from "react-router-dom";
import { CommonHash } from "app/types";
const columns = [
  { name: 'id', header: 'ID', type: 'number', defaultWidth: 60 },
  {
    name: 'name',
    defaultFlex: 1,
    header: 'Receta',
  },
  {
    name: 'kind',
    defaultFlex: 1,
    header: 'Tipo',
  },
  {
    name: 'total_quantity',
    defaultFlex: 1,
    header: 'Cantidad de personas',
  },
  {
    name: 'price',
    defaultFlex: 1,
    header: 'Precio',
  },
  {
    name: 'cooked_date',
    defaultFlex: 1,
    header: 'Fecha',
  },
  {
    name: 'action',
    defaultFlex: 1,
    header: 'Acciones',
    render: ({ data }: { data: CommonHash }) => {
      return (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Link to={`/recetas-cocinadas/${data.id}`}>
            <i className="bi bi-eye" />
          </Link>
          <Link to={`/recetas-cocinadas/${data.id}/editar`}>
            <i className="bi bi-pencil-square" />
          </Link>
        </div>
      );
    },
  },
]


const CookedReceipts: FunctionComponent = () => {
  const { isLoading, data: cookedReceipts, isFetching } = useCookedReceipts();
  
  if (isLoading || isFetching) return <Loader />
  const filterValue = [
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    { name: 'kind', operator: 'contains', type: 'string', value: '' },
  ];
  
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="mb-4"> Listado Recetas Cocinadas</h1>
        <Link to='crear-receta-cocinada' className="add-button">Crear Receta Cocinada</Link>
      </div>
      <Table columns={columns} data={cookedReceipts ? cookedReceipts.data : []} filters={filterValue} />
    </div>
  )
}

export default CookedReceipts;
