import { FormikErrors, FormikTouched } from 'formik';
import ErrorField from '../error_field/ErrorField';
import Select from 'react-select';
import { CommonHash } from 'app/types';

interface CompProps {
  label: string;
  name: string;
  value: string | number;
  options: CommonHash[];
  matchType: string;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>;
}

export default function SelectField({
  label,
  name,
  value,
  options,
  matchType,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
}: CompProps) {
  
  return (
    <div className="mb-3 ">
      <div className="d-flex flex-column">
        <label htmlFor={name} className="form-label">{label}</label>
        <Select
          isSearchable
          classNamePrefix="react-select"
          className={`form-control p-0`}
          placeholder="Seleccione..."
          name={name}
          options={options}
          value={options?.find(option => option[matchType] === value)}
          onChange={async (e) => {
            setFieldValue(name, e?.[matchType])
          }}
          onBlur={() => setFieldTouched(name, true)}
          styles={{
            control: (baseStyles: any) => ({
              ...baseStyles,
              height: '100%',
              width: '100%',
              borderRadius: '8px',
              border: 'none',
            }),
          }}
        />
      </div>
      <ErrorField fieldName={name} errors={errors} touched={touched} />
    </div>
  )
}