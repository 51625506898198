import React, { FunctionComponent } from "react";
import { useReceipt } from "services/api/receipts";
import { useParams, Link } from "react-router-dom";
import { Ingredient } from "app/types";
import Loader from "common/components/loader/Loader";


const ShowReceipt: FunctionComponent = () => {  
  const { receiptId } = useParams();

  const { data: receipt, isLoading, isFetching } = useReceipt(receiptId);

  if (isLoading || isFetching) return <Loader />

  return (
    <div>
      <div className="d-flex align-items-center mb-4 justify-content-between pe-5">
        <h1 className="m-0"> {receipt?.data.name}</h1>
        <div className="d-flex gap-5">
          <Link className="btn btn-primary" to={`/recetas/${receiptId}/crear-receta-cocinada`}>
            Crear receta
          </Link>
          <Link className="btn btn-outline-primary" to="editar">
            Editar receta
          </Link>
        </div>

      </div>
      <h3>Categoria:</h3>
      <span>{receipt?.data.kind}</span>
      <h3>Ingredientes</h3>
      {receipt?.data.ingredients.map((ingredient: Ingredient, index: number) => (
        <section key={ingredient.id}>
          <h4>Ingrediente {index + 1}</h4>
          <span>{ingredient.name}</span>
        </section>
      ))}
    </div>
  )
}

export default ShowReceipt