import React, { FunctionComponent } from "react";
import StockForm from "./StockForm";


const EditStock: FunctionComponent = () => {  
  return (
    <div>
      <h1 className="mb-4"> Editar Stock </h1>
      <StockForm />
    </div>
  )
}

export default EditStock;
