import React, { FunctionComponent } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Sidebar from 'features/backoffice/layout/Sidebar'
import useAuth from "hooks/useAuth";
const BackofficeLayout: FunctionComponent = () => {
  const { currentUser } = useAuth();
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }
  return (
    <div className="d-flex">
      <Sidebar />
      <div className="px-5 py-4 w-100">
        <Outlet />
      </div>
    </div>
  );
}
export default BackofficeLayout;