import React from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { CommonHash } from 'app/types';

interface CompProps {
  data: CommonHash[];
  columns: any[];
  filters?: any[];
  defaultSort?: any;
}

const Table = ({ data, columns, filters, defaultSort }: CompProps): React.ReactElement => {
  const gridStyle = { minHeight: 550 };
  return (
    <div>
      <ReactDataGrid
        idProperty="id"
        style={gridStyle}
        columns={columns}
        rowHeight={30}
        dataSource={data}
        pagination="local"
        defaultFilterValue={filters}
        defaultSortInfo={defaultSort}
      />
  </div>
  );
};

export default Table;

