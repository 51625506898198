import React, { FunctionComponent, useState } from "react";
import Select from 'react-select';
import { Formik, Form, FieldArray, FieldArrayRenderProps, FormikErrors, FormikTouched } from 'formik';
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { useProducts } from "services/api/products";
import {useAddReceipt, useUpdateReceipt, useReceipt, useNewReceipt } from "services/api/receipts";
import Loader from "common/components/loader/Loader";
import { ApiObject, CommonHash, Receipt } from "app/types";
import styles from './Receipt.module.scss'
import ErrorField from "common/components/error_field/ErrorField";
import AddFieldButton from "common/components/add_field_button/AddFieldButton";
import DeleteFieldButton from "common/components/delete_field_button/DeleteFieldButton";

interface FormData {
  name: string;
  kind: string;
  ingredients_attributes: CommonHash[];
}

interface IngredientFormProps {
  index: number;
  ingredientNumber: number;
  ingredients: CommonHash[];
  ingredient: CommonHash;
  products: CommonHash[];
  touched: FormikTouched<FormData>
  name: string;
  errors: FormikErrors<FormData>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<FormData>>;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  arrayHelpers: FieldArrayRenderProps;
  rendererKey: number;
  setRendererKey: (key: number) => void;
}


const kindOptions = [
  {label: 'Principal', value: 'main_course'},
  {label: 'Acompañamiento', value: 'side_dish'},
  {label: 'Ensalada', value: 'salad'},
  {label: 'Postre', value: 'dessert'},
]


const ReceiptForm: FunctionComponent = () => {
  const { receiptId } = useParams();
  let receipt: ApiObject<Receipt> | undefined;
  let isReceiptLoading = false;
  ({ data: receipt, isLoading: isReceiptLoading } = useNewReceipt(receiptId));
  ({ data: receipt, isLoading: isReceiptLoading } = useReceipt(receiptId));
  const navigate = useNavigate();
  const { isLoading, data: products } = useProducts();
  const { mutate: AddReceipt, isLoading: isPostLoading } = useAddReceipt(); 
  const { mutate: UpdateReceipt, isLoading: isPatchLoading } = useUpdateReceipt(); 
  const productsOptions = products?.data.map((product) => ({ label: product.name, value: product.id }))
  const [rendererKey, setRendererKey] = useState(0);

  const FormSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    kind: Yup.string().required('El tipo es requerido'),
    ingredients_attributes: Yup.array().of(
      Yup.object().shape({
        product_id: Yup.string().required('El producto es requerido'),
      })
    ),
  });
  const initialValues: FormData = {
    name: receipt?.data.name || '',
    kind:  kindOptions.find((e) => e?.label === receipt?.data.kind)?.value || '',
    ingredients_attributes:  receipt?.data.ingredients || [{product_id: ''}],
  }

  if (isLoading || isReceiptLoading) return <Loader />

  
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={async (values) => {
          if (receiptId === undefined) {
            AddReceipt({data: values});
            navigate('/recetas');

          } else {
            UpdateReceipt({ receiptId, data: values})
            navigate('/recetas');
          }
        }}
      >
        {({ setFieldValue, setFieldTouched, values, handleChange, handleBlur, errors, touched }) => {
          return (
            <Form className={styles.form}>
              <div className="mb-3">
                <div className="d-flex flex-column">
                  <label htmlFor="name" className="form-label">Nombre</label>
                  <div className="input-group">
                    <input type="text" className="form-control" name='name' onChange={handleChange} onBlur={handleBlur} value={values.name}/>
                  </div>
                </div>
                <ErrorField fieldName="name" errors={errors} touched={touched} />
              </div>
              <div className="mb-3 ">
                <div className="d-flex flex-column">
                  <label htmlFor="kind" className="form-label">Tipo</label>
                  <Select
                    isSearchable
                    classNamePrefix="react-select"
                    className={`form-control p-0`}
                    placeholder="Seleccione..."
                    name="kind"
                    options={kindOptions}
                    value={kindOptions.find(option => option.value === values.kind)}
                    onChange={(e) => setFieldValue('kind', e?.value)}
                    onBlur={() => setFieldTouched('kind', true)}
                    styles={{
                      control: (baseStyles: any) => ({
                        ...baseStyles,
                        height: '100%',
                        width: '100%',
                        borderRadius: '8px',
                        border: 'none',
                      }),
                    }}
                  />
                </div>
                <ErrorField fieldName="kind" errors={errors} touched={touched} />

              </div>
              <FieldArray
                name="ingredients_attributes"
                render={(arrayHelpers) => (
                  <div>
                    {values.ingredients_attributes && values.ingredients_attributes.length > 0 ? (
                      values.ingredients_attributes.map(
                        (ingredient: CommonHash, index: number) => {
                          const totalDestroyed = values.ingredients_attributes.filter(ingredient => ingredient._destroy === true).length;
                          const ingredientNumber = index + 1 - totalDestroyed;
                          const totalVisibleIngredients = values.ingredients_attributes.length - totalDestroyed;
                          return (
                            !ingredient._destroy && (
                              <div key={index} className={styles.landingComponentItem}>
                                <IngredientForm
                                  index={index}
                                  ingredientNumber={ingredientNumber}
                                  ingredients={values.ingredients_attributes}
                                  ingredient={ingredient}
                                  errors={errors}
                                  touched={touched}
                                  name={`ingredients_attributes[${index}]`}
                                  products={productsOptions || []}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                  handleChange={handleChange}
                                  arrayHelpers={arrayHelpers}
                                  rendererKey={rendererKey}
                                  setRendererKey={setRendererKey}
                                />
                                {totalVisibleIngredients === ingredientNumber && ( 
                                  <AddFieldButton index={index} arrayHelpers={arrayHelpers} rendererKey={rendererKey} setRendererKey={setRendererKey} />
                                )}
                              </div>
                            )
                          )
                        }
                      )
                    ) : (
                        <AddFieldButton index={0} arrayHelpers={arrayHelpers} rendererKey={rendererKey} setRendererKey={setRendererKey} />
                    )}
                  </div>
                )}
              />
              <div className="d-flex align-items-center justify-content-center">
                <button type="submit" className="mt-4 submit-button" disabled={isPostLoading || isPatchLoading}>{receiptId !== undefined ? 'Editar Receta' : 'Crear Receta'}</button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

const IngredientForm = ({
  index,
  ingredientNumber,
  ingredients,
  ingredient,
  name,
  products,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  arrayHelpers,
  rendererKey,
  setRendererKey,
}: IngredientFormProps): React.ReactElement => {
  return (
    <div className="mt-4">
      <div className="d-flex align-items-center gap-4 text-center">
        <h4 className="m-0">Ingrediente {ingredientNumber}</h4>
        <DeleteFieldButton 
          index={index}
          name={name}
          element={ingredient}
          elementsArray={ingredients}
          arrayHelpers={arrayHelpers}
          rendererKey={rendererKey}
          setFieldValue={setFieldValue}
          setRendererKey={setRendererKey} />
      </div>
      <div className="mb-3">
        <div className="d-flex flex-column">
          <label htmlFor="product_id" className="form-label">Producto</label>
          <Select
            isSearchable
            id={String(index)}
            classNamePrefix="react-select"
            className={`form-control p-0`}
            placeholder="Seleccione..."
            name={`${name}.product_id`}
            options={products}
            value={products.find(option => option.value === ingredient.product_id)}
            onChange={(e) => setFieldValue(`${name}.product_id`, e?.value)}
            onBlur={() => setFieldTouched(`${name}.product_id`, true)}
            styles={{
              control: (baseStyles: any) => ({
                ...baseStyles,
                height: '100%',
                width: '100%',
                borderRadius: '8px',
                border: 'none',
              }),
            }}
          />
        </div>
        <ErrorField fieldName={`${name}.product_id`} errors={errors} touched={touched} />

      </div>
    </div>
  )
}


export default ReceiptForm;


