import React, { FunctionComponent, useState } from "react";
import { Formik, Form, FieldArray, FieldArrayRenderProps, FormikErrors, FormikTouched } from 'formik';
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { useAddCookedReceipt, useUpdateCookedReceipt, useCookedReceipt, useNewCookedReceipt } from "services/api/cookedReceipts";
import { useReceipt, useReceiptIngredients  } from "services/api/receipts"
import { useProduct } from "services/api/products";
import Loader from "common/components/loader/Loader";
import { ApiObject, CommonHash, CookedReceipt } from "app/types";
import styles from './CookedReceipt.module.scss'
import InputField from "common/components/input_field/InputField";
import SelectField from "common/components/select_field/SelectField";
interface FormData {
  receipt_id: number;
  total_quantity: number;
  cooked_date: string;
  ingredients_attributes: CommonHash[];
}

interface IngredientFormProps {
  index: number;
  ingredientNumber: number;
  ingredients: CommonHash[];
  ingredient: CommonHash;
  touched: FormikTouched<FormData>
  name: string;
  errors: FormikErrors<FormData>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<FormData>>;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  arrayHelpers: FieldArrayRenderProps;
  rendererKey: number;
  setRendererKey: (key: number) => void;
}


const measurementUnitOptions = [
  {label: 'Kg', value: 'kg'},
  {label: 'Unidad', value: 'un'},
  {label: 'Lt', value: 'lt'},
]


const CookedReceiptForm: FunctionComponent = () => {
  const { receiptId, cookedReceiptId } = useParams();
  const navigate = useNavigate();
  let cookedReceipt: ApiObject<CookedReceipt> | undefined;
  let isCookedReceiptLoading = false;
  ({ data: cookedReceipt, isLoading: isCookedReceiptLoading } = useNewCookedReceipt(receiptId));
  ({ data: cookedReceipt, isLoading: isCookedReceiptLoading } = useCookedReceipt(cookedReceiptId));
  const {data: receipt, isLoading: isReceiptLoading } = useReceipt(receiptId);
  const { data: ingredients, isLoading: isIngredientsLoading } = useReceiptIngredients(parseInt(receiptId || '0'));
  const { mutate: AddCookedReceipt, isLoading: isPostLoading } = useAddCookedReceipt(); 
  const { mutate: UpdateCookedReceipt, isLoading: isPatchLoading } = useUpdateCookedReceipt(); 
  const [rendererKey, setRendererKey] = useState(0);

  const FormSchema = Yup.object().shape({
    receipt_id: Yup.string().required('La receta es requerido'),
    total_quantity: Yup.number().required('La cantidad es requerida').min(1, 'La cantidad tiene que ser mayor a 0'),
    cooked_date: Yup.string().required(),
    ingredients_attributes: Yup.array().of(
      Yup.object().shape({
        product_id: Yup.string().required('El producto es requerido'),
        // quantity: Yup.number().when('cooked_date', (cooked_date: any, schema) => {
        //   const today = new Date().toJSON().slice(0,10);
        //   const con = true;
        //   if (con === true) { // Implementa getCurrentDate según tu lógica para obtener la fecha actual
        //     return schema.test('check-quantity', 'La cantidad no puede ser mayor a la cantidad restante en stock', function(value) {
        //       let cooked_date = undefined;
        //       if (this.parent && this.parent.parent) {
        //         ({ cooked_date } = this.parent.parent)
        //       }
        //       const { left_on_stock } = this.parent;
        //       console.log('value', value);
        //       console.log(cooked_date)
        //       console.log(cooked_date === today)
        //       if ( cooked_date && cooked_date === today) {
        //         return (value || 0 ) <= left_on_stock || this.createError({
        //           path: this.path,
        //           message: 'La cantidad no puede ser mayor a la cantidad restante en stock'
        //         });
        //       } else {
        //         return true
        //       }
        //     });
        //   } else {
        //     return schema;
        //   }
        // }).required('La cantidad es requerida').min(0.1, 'La cantidad tiene que ser mayor a 0'),
        quantity: Yup.number().required('La cantidad es requerida').min(0.001, 'La cantidad tiene que ser mayor a 0'),
        measurement_unit: Yup.string().required('La unidad de medida es requerida'),

      })
    ),
  });
  const initialValues: FormData = {
    receipt_id: parseInt(receiptId || '0'),
    total_quantity:  cookedReceipt?.data.total_quantity || 0,
    cooked_date: cookedReceipt?.data.cooked_date || '',
    ingredients_attributes:  cookedReceipt?.data.ingredients || ingredients?.data || [{ product_id: '' }],
  }

  if (isCookedReceiptLoading || isReceiptLoading || isIngredientsLoading) return <Loader />

  
  return (
    <div>
      <h3>Receta: {receipt?.data.name || cookedReceipt?.data.name}</h3>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={async (values) => {
          if (cookedReceiptId === undefined) {
            AddCookedReceipt({receiptId: receiptId, data: values});
            navigate("/recetas-cocinadas");
          } else {
            UpdateCookedReceipt({ cookedReceiptId, data: values})
            navigate("/recetas-cocinadas");
          }
        }}
      >
        {({ setFieldValue, setFieldTouched, values, handleChange, handleBlur, errors, touched }) => {
          return (
            <Form className={styles.form}>
              <InputField label="Cantidad de personas" name="total_quantity" value={values.total_quantity} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
              <InputField label="Fecha en que se servirá(YYYY-MM-DD)" name="cooked_date" value={values.cooked_date} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
              <FieldArray
                name="ingredients_attributes"
                render={(arrayHelpers) => (
                  <div>
                    {values.ingredients_attributes.map(
                      (ingredient: CommonHash, index: number) => {
                        const totalDestroyed = values.ingredients_attributes.filter(ingredient => ingredient._destroy === true).length;
                        const ingredientNumber = index + 1 - totalDestroyed;
                        return (
                          <div key={index} className={styles.landingComponentItem}>
                            <IngredientForm
                              index={index}
                              ingredientNumber={ingredientNumber}
                              ingredients={values.ingredients_attributes}
                              ingredient={ingredient}
                              errors={errors}
                              touched={touched}
                              name={`ingredients_attributes[${index}]`}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              arrayHelpers={arrayHelpers}
                              rendererKey={rendererKey}
                              setRendererKey={setRendererKey}
                            />
                          </div>
                        )
                      }
                    )}
                  </div>
                )}
              />
              <div className="d-flex align-items-center justify-content-center">
                <button type="submit" className="mt-4 submit-button" disabled={isPostLoading || isPatchLoading}>{cookedReceiptId !== undefined ? 'Editar Receta Cocinada' : 'Crear Receta Cocinada'}</button>
              </div>
            </Form>
          )
        }
      }
      </Formik>
    </div>
  )
}

const IngredientForm = ({
  index,
  ingredientNumber,
  ingredients,
  ingredient,
  name,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  arrayHelpers,
  rendererKey,
  setRendererKey,
}: IngredientFormProps): React.ReactElement => {
  return (
    <div className="mt-4">
      <h4 className="m-0">{ingredient.name}</h4>
      <InputField label={`Cantidad: (${ingredient.left_on_stock} en stock)`} name={`${name}.quantity`} value={ingredient.quantity} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
      <SelectField label="Unidad de medida" name={`${name}.measurement_unit`} value={ingredient.measurement_unit} options={measurementUnitOptions} matchType="value" errors={errors} touched={touched} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
    </div>
  )
}


export default CookedReceiptForm;


