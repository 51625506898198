import React, { FunctionComponent } from "react";
import { useProductStocks, useProduct } from "services/api/products";
import Table from "common/components/table/Table";
import Loader from "common/components/loader/Loader";
import { CommonHash } from "app/types";
import { Link, useParams } from "react-router-dom";

const columns = [
  { name: 'id', header: 'ID', type: 'number', defaultWidth: 60 },
  {
    name: 'total_quantity',
    defaultFlex: 1,
    header: 'Cantidad inicial',
  },
  {
    name: 'current_quantity',
    defaultFlex: 1,
    header: 'Cantidad actual',
  },
  {
    name: 'price',
    defaultFlex: 1,
    header: 'Precio',
  },
  {
    name: 'status',
    defaultFlex: 1,
    header: 'Estado',
  },
  {
    name: 'action',
    defaultFlex: 1,
    header: 'Acciones',
    render: ({ data }: { data: CommonHash }) => {
      return (
        <div style={{ display: 'flex', gap: '8px' }}>
          {/* <Link to={`stocks/${data.id}`}>
            <i className="bi bi-eye" />
          </Link> */}
          <Link to={`${data.id}/editar`}>
            <i className="bi bi-pencil-square" />
          </Link>
        </div>
      );
    },
  }
]


const ProductStocks: FunctionComponent = () => {
  const { productId } = useParams();

  const { data: product, isLoading: isProductLoading } = useProduct(productId);
  const { data: stocks ,isLoading: isStocksLoading} = useProductStocks(productId);

  console.log('stocks', stocks);
  console.log('products', product);
  
  if (isStocksLoading || isProductLoading) return <Loader />
  const filterValue = [
    { name: 'status', operator: 'contains', type: 'string', value: '' },
  ];
  
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="mb-4"> Listado Stocks del producto {`${product?.data.name}`} </h1>
        <Link to='crear-stock' className="add-button">Crear Stock</Link>
      </div>
      <Table columns={columns} data={stocks ? stocks.data : []} filters={[]} />
    </div>
  )
}

export default ProductStocks;
