import React, { FunctionComponent } from "react";
import ProductCategoryForm from "./ProductCategoryForm";


const NewProductCategory: FunctionComponent = () => {  
  return (
    <div>
      <h1 className="mb-4"> Crear Categoria </h1>
      <ProductCategoryForm />
    </div>
  )
}

export default NewProductCategory;
