import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useFileImports } from "services/api/fileImports";
import Table from "common/components/table/Table";
import Loader from "common/components/loader/Loader";

const columns = [
  { name: 'id', header: 'ID', type: 'number', defaultWidth: 60 },
  {
    name: 'name',
    defaultFlex: 1,
    header: 'Nombre',
  },
  {
    name: 'kind',
    defaultFlex: 1,
    header: 'Tipo',
  },
  {
    name: 'status',
    defaultFlex: 1,
    header: 'Estado',
  },

]

const FileImports: FunctionComponent = () => {
  const { isLoading, data: fileImports, isFetching } = useFileImports();
  
  if (isLoading || isFetching) return <Loader />
  const filterValue = [
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    { name: 'kind', operator: 'contains', type: 'string', value: '' },
  ];
  
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="mb-4"> Listado Archivos subidos </h1>
        <Link to='crear' className="add-button">Subir archivo</Link>
      </div>
      <Table columns={columns} data={fileImports ? fileImports.data : []} filters={filterValue} />
    </div>
  )
}

export default FileImports;
