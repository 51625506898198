import React, { FunctionComponent } from "react";
import ReceiptForm from "./ReceiptForm";


const NewReceipt: FunctionComponent = () => {  
  return (
    <div>
      <h1 className="mb-4"> Crear Receta </h1>
      <ReceiptForm />
    </div>
  )
}

export default NewReceipt;
