import React, { FunctionComponent } from "react";
import { useStocks } from "services/api/stocks";
import Table from "common/components/table/Table";
import Loader from "common/components/loader/Loader";

const columns = [
  { name: 'id', header: 'ID', type: 'number', defaultWidth: 60 },
  {
    name: 'name',
    defaultFlex: 1,
    header: 'Producto',
  },
  {
    name: 'measurement_unit',
    defaultFlex: 1,
    header: 'Unidad de medida',
  },
  {
    name: 'quantity',
    defaultFlex: 1,
    type: 'number',
    header: 'En stock',
  },
  {
    name: 'mean_stock_price',
    defaultFlex: 1,
    type: 'number',
    header: 'Precio promedio stock',
  },
]


const Invoices: FunctionComponent = () => {
  const { isLoading, data: stocks, isFetching } = useStocks();
  
  if (isLoading || isFetching) return <Loader />
  const filterValue = [
    { name: 'name', operator: 'contains', type: 'string', value: '' },
  ];
  
  return (
    <div>
      <h1> Listado de Stocks </h1>
      <Table columns={columns} data={stocks ? stocks.data : []} filters={filterValue} />
    </div>
  )
}

export default Invoices;
