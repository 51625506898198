import React, { FunctionComponent, useState } from "react";
import { Formik, Form, FieldArray, FieldArrayRenderProps, FormikErrors, FormikTouched } from 'formik';
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { useAddStock, useUpdateStock, useStock, useNewStock } from "services/api/stocks";
import { useProduct } from "services/api/products"
import Loader from "common/components/loader/Loader";
import { ApiObject, CommonHash, Stock } from "app/types";
import styles from './Stock.module.scss'
import InputField from "common/components/input_field/InputField";
import SelectField from "common/components/select_field/SelectField";

interface FormData {
  product_id: number;
  total_quantity: number;
  price: number;
  status: string;
}


const StockForm: FunctionComponent = () => {
  const { productId, stockId } = useParams();
  const navigate = useNavigate();
  let stock: ApiObject<Stock> | undefined;
  let isStockLoading = false;
  ({ data: stock, isLoading: isStockLoading } = useNewStock(productId));
  ({ data: stock, isLoading: isStockLoading } = useStock(stockId));
  const {data: product, isLoading: isProductLoading } = useProduct(productId);
  const { mutate: AddStock, isLoading: isPostLoading } = useAddStock(); 
  const { mutate: UpdateStock, isLoading: isPatchLoading } = useUpdateStock(); 

  const FormSchema = Yup.object().shape({
    product_id: Yup.string().required('El producto es requerido'),
    total_quantity: Yup.number().required('La cantidad es requerida').min(0.001, 'La cantidad tiene que ser mayor a 0'),
    price: Yup.string().required(),
    status: Yup.string().required(),
  });

  const initialValues: FormData = {
    product_id: parseInt(productId || '0'),
    total_quantity:  10000,
    price: stock?.data.price || 0,
    status: 'active',
  }

  if (isStockLoading || isProductLoading) return <Loader />

  
  return (
    <div>
      <h3>Producto: {product?.data.name}</h3>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={async (values) => {
          if (stockId === undefined) {
            AddStock({productId: productId, data: values});
            navigate(`/productos/${productId}/stocks`);
          } else {
            UpdateStock({ stockId, data: values})
            navigate(`/productos/${productId}/stocks`)
          }
        }}
      >
        {({ values, handleChange, handleBlur, errors, touched }) => {
          return (
            <Form className={styles.form}>
              <InputField label="Precio" name="price" value={values.price} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
              <div className="d-flex align-items-center justify-content-center">
                <button type="submit" className="mt-4 submit-button" disabled={isPostLoading || isPatchLoading}>{stockId !== undefined ? 'Editar Stock' : 'Crear Stock'}</button>
              </div>
            </Form>
          )
        }
      }
      </Formik>
    </div>
  )
}


export default StockForm;


