import { FieldArrayRenderProps } from 'formik';
import { CommonHash } from 'app/types';

interface CompProps {
  index: number;
  name: string;
  element: CommonHash;
  elementsArray: CommonHash[];
  arrayHelpers: FieldArrayRenderProps;
  rendererKey: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setRendererKey: (key: number) => void;
}

export default function DeleteFieldButton({
  index,
  name,
  element,
  elementsArray,
  arrayHelpers,
  rendererKey,
  setFieldValue,
  setRendererKey,
}: CompProps) {
  const deleteAmount = elementsArray.filter((ing) => ing._destroy === true).length;
  const canDelete = () => elementsArray.length > 1 && elementsArray.length - deleteAmount > 1;
  return (
    canDelete() ? (
      <button
        type="button"
        className="delete-button"
        onClick={() => {
          if (element.id !== undefined) {
            setFieldValue(`${name}._destroy`, true);
          } else {
            const updatedIngredients = elementsArray.filter((_, movingIndex) => index !== movingIndex);
            setFieldValue('ingredients_attributes', updatedIngredients);
          }
        }}
      >
        Delete
      </button>
    ) : (
      <></>
    )
  )
}