import axios from 'axios'
import Notification from 'common/components/notification/Notification';
let API_URL = 'https://pedroherreraj.me/api/v1';
if (process.env.NODE_ENV === 'development') {
  API_URL = 'http://localhost:3000/api/v1';
}
const api = axios.create({ baseURL: API_URL} )

const request = async ({ authorizationToken, ...options }: any) => {
  const apiConfig = {
    headers: {
      ...(authorizationToken && { Authorization: `Bearer ${authorizationToken}` }),
    },
    ...options,
  };

  const onSuccess = (response: any ) => response;
  const onError = (error: any) => {
    if (error.response.data['ingredients.base'].length > 0) {
      error.response.data['ingredients.base'].map((msg: string) => Notification({type: 'error', message: msg}))
    }
    return error;
  }

  return api(apiConfig).then(onSuccess).catch(onError);
};

export default request;


