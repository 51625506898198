import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useCounters } from "services/api/counters";
import Table from "common/components/table/Table";
import Loader from "common/components/loader/Loader";

const columns = [
  { name: 'id', header: 'ID', type: 'number', defaultWidth: 60 },
  {
    name: 'cooked_receipt',
    defaultFlex: 1,
    header: 'Receta',
  },
  {
    name: 'total',
    defaultFlex: 1,
    header: 'Total',
  },
]

const Counters: FunctionComponent = () => {
  const { isLoading, data: counters, isFetching } = useCounters();
  
  if (isLoading || isFetching) return <Loader />
  const filterValue = [
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    { name: 'kind', operator: 'contains', type: 'string', value: '' },
  ];
  
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="mb-4"> Listado de contadores </h1>
        <Link to='contadores-diarios' className="add-button">Contadores diarios</Link>
      </div>
      <Table columns={columns} data={counters ? counters.data : []} filters={filterValue} />
    </div>
  )
}

export default Counters;
