import request from 'utils/axios-utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiList, FileImport, CommonHash } from 'app/types';
import useAuth from 'hooks/useAuth';

export function useFileImports() {
  const { currentUser } = useAuth();
  return useQuery<ApiList<FileImport>>("file_imports", async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: '/file_imports', method: 'GET' });
    return response.data;
  });
}

export function useAddFileImport() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/file_imports`, method: 'POST', data});
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('file_imports')
      // queryClient.setQueriesData('cooked_receipts', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
};