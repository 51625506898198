import React, { FunctionComponent } from "react";
import { useCookedReceipt } from "services/api/cookedReceipts";
// eslint-disable-next-line
import { redirect, useParams, Link } from "react-router-dom";
import { Ingredient } from "app/types";
import Loader from "common/components/loader/Loader";


const ShowCookedReceipt: FunctionComponent = () => {  
  const { cookedReceiptId } = useParams();

  const { data: cookedReceipt, isLoading, isFetching } = useCookedReceipt(cookedReceiptId);

  if (isLoading || isFetching) return <Loader />


  return (
    <div>
      <div className="d-flex align-items-center mb-4 justify-content-between pe-5">
        <h1 className="m-0"> {cookedReceipt?.data.name}</h1>
        <div className="d-flex gap-5">
          <Link className="btn btn-primary" to={`/recetas/${cookedReceipt?.data.receipt_id}/crear-receta-cocinada`}>
            Crear receta cocinada
          </Link>
          <Link className="btn btn-outline-primary" to="editar">
            Editar receta cocinada
          </Link>
        </div>

      </div>
      <h6>Precio: {cookedReceipt?.data.price} </h6>
      <h6>Categoria: {cookedReceipt?.data.kind}</h6>
      <h6>Cantidad de personas: {cookedReceipt?.data.total_quantity}</h6>
      <h6>Fecha: {cookedReceipt?.data.cooked_date}</h6>
      <h4>Ingredientes</h4>
      {cookedReceipt?.data.ingredients.map((ingredient: Ingredient, index: number) => (
        <section key={ingredient.id} className="mt-3">
          <h5 className="bold" style={{fontWeight: 600}}>{ingredient.name}</h5>
          <h6>Cantidad: {ingredient.quantity} {ingredient.measurement_unit}</h6>
          <h6>Precio: {ingredient.price}</h6>
        </section>
      ))}
    </div>
  )
}

export default ShowCookedReceipt