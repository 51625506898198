import { FunctionComponent } from "react";
import { Formik, Form } from 'formik';
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { useAddProductCategory, useUpdateProductCategory, useProductCategory, useNewProductCategory } from "services/api/productCategories";
import Loader from "common/components/loader/Loader";
import styles from './ProductCategory.module.scss'
import InputField from "common/components/input_field/InputField";
import { ProductCategory, ApiObject } from "app/types";
interface FormData {
  name: string;
}

const ProductCategoryForm: FunctionComponent = () => {
  const { productCategoryId } = useParams();
  const navigate = useNavigate();
  let productCategory: ApiObject<ProductCategory> | undefined;
  let isProductCategoryLoading = false;
  ({ data: productCategory, isLoading: isProductCategoryLoading } = useNewProductCategory(productCategoryId));
  ({ data: productCategory, isLoading: isProductCategoryLoading } = useProductCategory(productCategoryId));
  const { mutate: AddProductCategory, isLoading: isPostLoading } = useAddProductCategory(); 
  const { mutate: UpdateProductCategory, isLoading: isPatchLoading } = useUpdateProductCategory(); 

  const FormSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
  });
  const initialValues: FormData = {
    name: productCategory?.data.name || '',
  }

  if (isProductCategoryLoading) return <Loader />

  
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={async (values) => {
          if (productCategoryId === undefined) {
            AddProductCategory({productCategoryId: productCategoryId, data: values});
            navigate("/categorias");
          } else {
            UpdateProductCategory({ productCategoryId, data: values})
            navigate("/categorias");
          }
        }}
      >
        {({ values, handleChange, handleBlur, errors, touched }) => {
          return (
            <Form className={styles.form}>
              <InputField label="Nombre categoria" name="name" value={values.name} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
              <div className="d-flex align-items-center justify-content-center">
                <button type="submit" className="mt-4 submit-button" disabled={isPostLoading || isPatchLoading}>{productCategoryId !== undefined ? 'Editar' : 'Crear'}</button>
              </div>
            </Form>
          )
        }
      }
      </Formik>
    </div>
  )
}

export default ProductCategoryForm;


