
import request from 'utils/axios-utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiList, ApiObject, CommonHash, Receipt, Ingredient } from 'app/types';
import useAuth from 'hooks/useAuth';

export function useReceipts() {
  const { currentUser } = useAuth();
  return useQuery<ApiList<Receipt>>("receipts", async () => {
    const response = await request({ authorizationToken: currentUser?.authorizationToken, url: '/receipts', method: 'GET'})
    return response.data;
  },{
    refetchOnWindowFocus: false, // Desactivar refetch on focus window
  });
}
export function useNewReceipt(receiptId: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<Receipt>>('receipt', async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: '/receipts/new', method: 'GET' });
    return response.data;
  }, { enabled: receiptId === undefined }
  )
}
export function useReceipt(id: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<Receipt>>(['receipt', id], async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: `/receipts/${id}`, method: 'GET' });
    return response.data;
  }, { enabled: id !== undefined })
}

export function useAddReceipt() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/receipts`, method: 'POST', data});
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('receipts')
      // queryClient.setQueriesData('receipts', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}
export function useUpdateReceipt() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ receiptId, data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/receipts/${receiptId}`, method: 'PATCH', data});
  } , {
    onSuccess: () => {
      queryClient.invalidateQueries('receipts')
      // queryClient.setQueriesData('receipts', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}

export function useReceiptIngredients(id: number)  {
  const { currentUser } = useAuth();
  return useQuery<ApiList<Ingredient>>(['receipt', id], async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: `/receipts/${id}/ingredients`, method: 'GET' });
    return response.data;
  }, { enabled: id !== 0 })
}