import { FunctionComponent, useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import { useAddProductSubCategory, useUpdateProductSubCategory, useProductSubCategory, useNewProductSubCategory } from "services/api/productSubCategories";
import Loader from "common/components/loader/Loader";
import styles from './ProductSubCategory.module.scss'
import InputField from "common/components/input_field/InputField";
import SelectField from "common/components/select_field/SelectField";
import { ProductSubCategory, ApiObject } from "app/types";
import { useProductCategories } from "services/api/productCategories";
interface FormData {
  product_category_id: number;
  name: string;
}

const ProductSubCategoryForm: FunctionComponent = () => {
  const { productSubCategoryId } = useParams();
  const navigate = useNavigate();
  let productSubCategory: ApiObject<ProductSubCategory> | undefined;
  let isProductSubCategoryLoading = false;
  ({ data: productSubCategory, isLoading: isProductSubCategoryLoading } = useNewProductSubCategory(productSubCategoryId));
  ({ data: productSubCategory, isLoading: isProductSubCategoryLoading } = useProductSubCategory(productSubCategoryId));
  const { data: productCategories, isLoading: isProductCategoriesLoading } = useProductCategories();
  const { mutate: AddProductSubCategory, isLoading: isPostLoading } = useAddProductSubCategory(); 
  const { mutate: UpdateProductSubCategory, isLoading: isPatchLoading } = useUpdateProductSubCategory(); 
  const FormSchema = Yup.object().shape({
    product_category_id: Yup.number().required('La categoria es requerido'),
    name: Yup.string().required('El nombre es requerido'),
  });
  const initialValues: FormData = {
    product_category_id: productSubCategory?.data.product_category_id || 0,
    name: productSubCategory?.data.name || '',
  }

  const productCategoriesOptions = productCategories?.data.map((productCategory) => (
    {
      label: productCategory.name,
      value: productCategory.id,
    } 
  )) || [];

  if (isProductSubCategoryLoading || isProductCategoriesLoading) return <Loader />

  
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={async (values) => {
          if (productSubCategoryId === undefined) {
            AddProductSubCategory({productSubCategoryId: productSubCategoryId, data: values});
            navigate("/sub_categorias");
          } else {
            UpdateProductSubCategory({ productSubCategoryId, data: values})
            navigate("/sub_categorias");
          }
        }}
      >
        {({ values, handleChange, handleBlur, setFieldTouched, setFieldValue, errors, touched }) => {
          return (
            <Form className={styles.form}>
              <SelectField label="Categoria" name="product_category_id" value={values.product_category_id} options={productCategoriesOptions} matchType="value" errors={errors} touched={touched} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />

              <InputField label="Nombre sub categoria" name="name" value={values.name} handleChange={handleChange} handleBlur={handleBlur} errors={errors} touched={touched} />
              <div className="d-flex align-items-center justify-content-center">
                <button type="submit" className="mt-4 submit-button" disabled={isPostLoading || isPatchLoading}>{productSubCategoryId !== undefined ? 'Editar' : 'Crear'}</button>
              </div>
            </Form>
          )
        }
      }
      </Formik>
    </div>
  )
}

export default ProductSubCategoryForm;


