import request from 'utils/axios-utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiList, Stock, CommonHash, ApiObject } from 'app/types';
import useAuth from 'hooks/useAuth';

export function useStocks() {
  const { currentUser } = useAuth();
  return useQuery<ApiList<Stock>>("stocks", async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: '/stocks', method: 'GET' });
    return response.data;
  });
}

export function useNewStock(stockId: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<Stock>>('stock', async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: '/stocks/new', method: 'GET' });
    return response.data;
  }, { enabled: stockId === undefined }
  )
}
export function useStock(id: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<Stock>>(['stock', id], async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: `/stocks/${id}`, method: 'GET' });
    return response.data;
  }, { enabled: id !== undefined })
}

export function useAddStock() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/stocks`, method: 'POST', data});
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('stocks')
      // queryClient.setQueriesData('stocks', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}
export function useUpdateStock() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ stockId, data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/stocks/${stockId}`, method: 'PATCH', data});
  } , {
    onSuccess: () => {
      queryClient.invalidateQueries('stocks')
      // queryClient.setQueriesData('stocks', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}