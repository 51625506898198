import request from 'utils/axios-utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiList, Counter, CommonHash } from 'app/types';
import useAuth from 'hooks/useAuth';

export function useCounters() {
  const { currentUser } = useAuth();
  return useQuery<ApiList<Counter>>("counters", async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: '/counters', method: 'GET' });
    return response.data;
  });
}

export function useDailyCounters() {
  const { currentUser } = useAuth();
  return useQuery<ApiList<Counter>>("daily_counters", async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: 'counters/daily_counters', method: 'GET' });
    return response.data;
  });
}

export function useUpdateDailyCounters() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `counters/patch_daily_counters`, method: 'PATCH', data});
  } , {
    onSuccess: () => {
      queryClient.invalidateQueries('receipts')
      // queryClient.setQueriesData('receipts', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}