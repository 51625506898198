
import request from 'utils/axios-utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiList, ApiObject, CommonHash, ProductCategory } from 'app/types';
import useAuth from 'hooks/useAuth';

export function useProductCategories() {
  const { currentUser } = useAuth();
  return useQuery<ApiList<ProductCategory>>("product_categories", async () => {
    const response = await request({ authorizationToken: currentUser?.authorizationToken, url: '/product_categories', method: 'GET'})
    return response.data;
  },{
    refetchOnWindowFocus: false, // Desactivar refetch on focus window
  });
}
export function useNewProductCategory(productCategoryId: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<ProductCategory>>('product_category', async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: '/product_categories/new', method: 'GET' });
    return response.data;
  }, { enabled: productCategoryId === undefined }
  )
}
export function useProductCategory(id: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<ProductCategory>>(['product_category', id], async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: `/product_categories/${id}`, method: 'GET' });
    return response.data;
  }, { enabled: id !== undefined })
}

export function useAddProductCategory() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/product_categories`, method: 'POST', data});
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('product_categories')
      // queryClient.setQueriesData('product_categories', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}
export function useUpdateProductCategory() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ productCategoryId, data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/product_categories/${productCategoryId}`, method: 'PATCH', data});
  } , {
    onSuccess: () => {
      queryClient.invalidateQueries('product_categories')
      // queryClient.setQueriesData('product_categories', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}
