import React, { FunctionComponent, useState } from "react";
import { Formik, Form, FieldArray, FieldArrayRenderProps, FormikErrors, FormikTouched } from 'formik';
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { useDailyCounters, useUpdateDailyCounters } from "services/api/counters";
import Loader from "common/components/loader/Loader";
import { ApiObject, CommonHash, CookedReceipt } from "app/types";
import InputField from "common/components/input_field/InputField";
import SelectField from "common/components/select_field/SelectField";
interface FormData {
  counters: CommonHash[];
}

interface IngredientFormProps {
  index: number;
  counters: CommonHash[];
  counter: CommonHash;
  touched: FormikTouched<FormData>
  name: string;
  errors: FormikErrors<FormData>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<FormData>>;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  arrayHelpers: FieldArrayRenderProps;
  rendererKey: number;
  setRendererKey: (key: number) => void;
}


const DailyCountersForm: FunctionComponent = () => {
  const navigate = useNavigate();
  let cookedReceipt: ApiObject<CookedReceipt> | undefined;
  let isCookedReceiptLoading = false;
  const {data: counters, isLoading: isCountersLoading } = useDailyCounters();
  const { mutate: UpdateCookedReceipt, isLoading: isPatchLoading } = useUpdateDailyCounters(); 
  const [rendererKey, setRendererKey] = useState(0);

  const FormSchema = Yup.object().shape({
    counters: Yup.array().of(
      Yup.object().shape({
        // cooked_receipt_id: Yup.string().required('La receta cocinada requerido'),
        total: Yup.number().required('La cantidad es requerida').min(0.1, 'La cantidad tiene que ser mayor a 0'),
      })
    ),
  });

  const initialValues: FormData = {
    counters: counters?.data || [],
  }

  if (isCountersLoading) return <Loader />

  

  return (
    <div>
      {/* <h3>Receta: {receipt?.data.name || cookedReceipt?.data.name}</h3> */}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          UpdateCookedReceipt({ data: values})
          navigate("/contadores");
        }}
      >
        {({ setFieldValue, setFieldTouched, values, handleChange, handleBlur, errors, touched }) => {
          return (
            // <Form className={styles.form}>
            <Form>
              <FieldArray
                name="ingredients_attributes"
                render={(arrayHelpers) => (
                  <div className='row'>
                    {values.counters.map(
                      (counter: CommonHash, index: number) => {
                        {/* <div key={index} className={styles.landingComponentItem}> */}
                        return (
                          <div key={index} className="col-3 mt-3">
                            <CountForm
                              index={index}
                              counters={values.counters}
                              counter={counter}
                              errors={errors}
                              touched={touched}
                              name={`counters[${index}]`}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              arrayHelpers={arrayHelpers}
                              rendererKey={rendererKey}
                              setRendererKey={setRendererKey}
                            />
                            {index % 3 == 0 && <div className="col-4"/>}
                          </div>
                        )
                      }
                    )}
                  </div>
                )}
              />
              <div className="d-flex align-items-center justify-content-center">
                <button type="submit" className="mt-4 submit-button" disabled={isPatchLoading}>Editar contadores</button>
              </div>
            </Form>
          )
        }
      }
      </Formik>
    </div>
  )
}

const CountForm = ({
  index,
  counters,
  counter,
  name,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  arrayHelpers,
  rendererKey,
  setRendererKey,
}: IngredientFormProps): React.ReactElement => {

  const increaseTotalValue = () => {
    setFieldValue(`${name}.total`, counter.total + parseFloat(inputValue))
    setInputValue('1')
  }
  const decreaseTotalValue = () => {
    setFieldValue(`${name}.total`, counter.total - parseFloat(inputValue))
    setInputValue('1')
  }
  const [inputValue, setInputValue] = useState('1')
  return (
    <div className="">
      <div className="d-flex align-items-center gap-3">
        <div style={{width: '100px'}} className="d-flex align-items-center">
          <h6 className="m-0">{counter.cooked_receipt}</h6>
          <h6 style={{marginLeft: '5px'}} className="my-0">{counter.total}</h6>
        </div>
        <div className="d-flex gap-2">
          <button type='button' className='btn-counter minus-btn' onClick={decreaseTotalValue}>-</button>
          <input type="text" className="form-control input-counter" value={inputValue} onChange={e => setInputValue(e.target.value)}/>
          <button type='button' className='btn-counter add-btn' onClick={increaseTotalValue}>+</button>
        </div>

      </div>
    </div>
  )
}


export default DailyCountersForm;


