
import request from 'utils/axios-utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiList, ApiObject, CommonHash, ProductSubCategory } from 'app/types';
import useAuth from 'hooks/useAuth';

export function useProductSubCategories() {
  const { currentUser } = useAuth();
  return useQuery<ApiList<ProductSubCategory>>("product_sub_categories", async () => {
    const response = await request({ authorizationToken: currentUser?.authorizationToken, url: '/product_sub_categories', method: 'GET'})
    return response.data;
  },{
    refetchOnWindowFocus: false, // Desactivar refetch on focus window
  });
}
export function useNewProductSubCategory(productSubCategoryId: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<ProductSubCategory>>('product_category', async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: '/product_sub_categories/new', method: 'GET' });
    return response.data;
  }, { enabled: productSubCategoryId === undefined }
  )
}
export function useProductSubCategory(id: string | undefined)  {
  const { currentUser } = useAuth();
  return useQuery<ApiObject<ProductSubCategory>>(['product_category', id], async () => {
    const response = await request({authorizationToken: currentUser?.authorizationToken,  url: `/product_sub_categories/${id}`, method: 'GET' });
    return response.data;
  }, { enabled: id !== undefined })
}

export function useAddProductSubCategory() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/product_sub_categories`, method: 'POST', data});
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('product_sub_categories')
      // queryClient.setQueriesData('product_sub_categories', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}
export function useUpdateProductSubCategory() {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(({ productSubCategoryId, data }: CommonHash) => {
    return request({authorizationToken: currentUser?.authorizationToken,  url: `/product_sub_categories/${productSubCategoryId}`, method: 'PATCH', data});
  } , {
    onSuccess: () => {
      queryClient.invalidateQueries('product_sub_categories')
      // queryClient.setQueriesData('product_sub_categories', (oldQueryData) => {
      //   return {
      //     ...oldQueryData,
      //     data: 
      //   }
      // })
    }
  })
}
