import { FieldArrayRenderProps } from 'formik';

interface CompProps {
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  rendererKey: number;
  setRendererKey: (key: number) => void;
}

export default function AddFieldButton({
  index,
  arrayHelpers,
  rendererKey,
  setRendererKey,
}: CompProps) {
  
  return (
    <button
      type="button"
      className="mt-2 add-button"
      onClick={() => {
        setRendererKey(rendererKey + 1);
        arrayHelpers.insert(index + 1, {
          product_id: '',
        });
      }}
    >
      + Ingrediente
    </button>
  )
}